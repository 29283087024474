* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}

img {
  max-width: 100%;
  height: auto;
}

.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
}

body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: #f6f6f6;
  word-spacing: 3px;
  /* letter-spacing: -1px; */
}

.content-container {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 50px;
  gap: 16px;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

.footer {
  font-family: system-ui !important;
  font-weight: 100;
  letter-spacing: 1.5px;
  padding: 16px 32px;
  border-top-width: 1px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  border-style: solid;
  border-color: #818181;
  width: calc(100vw - 32px);
}

.header {
  width: calc(100% - 26px);
  height: 88px;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  /* font-weight: bold; */
  /* position: -webkit-sticky;
  position: sticky; */
  top: 0;
  z-index: 999;
}

.header-item {
  /* width: 100%; */
  height: 64px;
  padding: 0 13px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.logo-text {
  /* font-family: "Bebas Neue"; */
  font-family: system-ui !important;
  font-weight: 100;
  color: #4c4c4c;
  font-size: 40px;
  letter-spacing: 5px;
}

.blockian-logo {
  max-width: 100%;
  max-height: 100%;
}

@keyframes blob-change {
  0% {
    d: path("M148.7 -134.9C193.7 -103.7 231.9 -51.9 232.4 0.6C233 53 196.1 106.1 151.1 128.6C106.1 151.1 53 143 -4.4 147.4C-61.8 151.8 -123.5 168.5 -151.2 146C-178.8 123.5 -172.4 61.8 -172.8 -0.4C-173.1 -62.5 -180.3 -124.9 -152.6 -156.1C-124.9 -187.3 -62.5 -187.1 -5.3 -181.8C51.9 -176.5 103.7 -166 148.7 -134.9");
  }

  50% {
    d: path("M115.4 -137.9C137.9 -92.9 136.4 -46.4 133.6 -2.8C130.8 40.8 126.6 81.6 104.1 118.4C81.6 155.2 40.8 188.1 -8.4 196.5C-57.5 204.8 -115 188.7 -151 151.9C-187 115 -201.5 57.5 -190.8 10.7C-180.1 -36.1 -144.1 -72.1 -108.1 -117.1C-72.1 -162.1 -36.1 -216.1 5.2 -221.2C46.4 -226.4 92.9 -182.9 115.4 -137.9");
  }

  100% {
    d: path("M148.7 -134.9C193.7 -103.7 231.9 -51.9 232.4 0.6C233 53 196.1 106.1 151.1 128.6C106.1 151.1 53 143 -4.4 147.4C-61.8 151.8 -123.5 168.5 -151.2 146C-178.8 123.5 -172.4 61.8 -172.8 -0.4C-173.1 -62.5 -180.3 -124.9 -152.6 -156.1C-124.9 -187.3 -62.5 -187.1 -5.3 -181.8C51.9 -176.5 103.7 -166 148.7 -134.9");
  }
}

.blob {
  animation: blob-change 4s infinite;
  opacity: 0.5;
}

.blob-container {
  position: absolute;
  top: 0;
  left: -1200px;
  transform: scaleX(-0.75);
}

.blob-delay {
  animation-delay: 0.3s;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}

.navbar-menu-container {
  display: none;
}

.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
}

.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}

.navbar-cart-icon {
  font-size: 1.15rem;
}

.primary-button:hover {
  background-color: rgb(231, 69, 174);
}

.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}

.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;

}

.demo-gif {
  height: 580px;
  border-radius: 45px;
}

.home-bannerImage-container {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -2;
  max-width: 700px;
}

.home-image-section {
  max-width: 600px;
  flex: 1;
}

.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.primary-heading {
  font-size: clamp(2rem, 3.5vw, 3.2rem);
  color: #4c4c4c;
  /* line-height: 5rem; */
  max-width: 680px;
}

.primary-text {
  font-size: clamp(0.9rem, 3vw, 1.2rem);
  max-width: 500px;
  color: #6a6a6a;
}

.primary-text-margin1 {
  margin: 1rem 0rem 2rem 0rem;
}

.primary-text-margin2 {
  margin: 1rem 0rem;
}

a {
  text-decoration: none !important;
}

.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #ea3ca4;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}

.secondary-button:hover {
  background-color: #ea3ca4;
}

.demo-section-container {
  /* width: 80vw;
  justify-self: center; */
}

.about-section-container {
  margin-top: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 36px;
}

.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}

.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
  width: 600px;
  display: flex;
  justify-content: center;
}

.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.primary-subheading {
  font-weight: 700;
  color: #ea3ca4;
  font-size: 1.15rem;
}

.about-buttons-container {
  margin-top: 2rem;
  display: flex;
  padding-bottom: 32px;
}

.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}

.contact-page-wrapper h1 {
  max-width: 900px !important;
}

.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}

.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}

.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}

.footer-wrapper {
  margin: 6rem 0rem;
  display: flex;
  margin-top: 10rem;
}

.footer-logo-container {
  max-width: 110px;
}

.footer-icons {
  margin-top: 2.5rem;
}

.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}

/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}

.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
}

.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}

.testimonial-section-bottom {
  margin: 2rem auto;
}

.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}

.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #ea3ca4;
}

.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}

.work-section-wrapper {
  margin-top: 8rem;
}

.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}

.work-section-top h1 {
  max-width: 700px !important;
}

.work-section-top {
  display: flex;
  gap: 36px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.blop-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.work-section-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
}

.work-section-info h2 {
  margin: 1rem 0rem;
}

.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.youtube {
  width: 800px;
  height: 450px;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }

  .primary-button {
    font-size: 1rem;
  }

  .home-bannerImage-container {
    max-width: 600px;
  }
}

@media (max-width: 800px) {
  .youtube {
    width: 90vw;
    height: calc(90vw * 0.52);
  }

  .nav-logo-container {
    max-width: 140px;
  }

  .navbar-links-container {
    display: none;
  }

  .navbar-menu-container {
    display: flex;
  }

  .home-bannerImage-container {
    max-width: 450px;
  }

  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .about-section-container {
    flex-direction: column;
    gap: 36px;
  }

  .home-image-section {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .about-section-image-container {
    width: 100%;
    max-width: 600px;
  }

  .primary-heading {
    text-align: center;
    max-width: 90%;
  }

  .primary-text {
    text-align: center;
    max-width: 80%;
  }

  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }

  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }

  .about-section-container {
    margin-top: 5rem !important;
  }

  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
    padding-bottom: 32px;
  }

  .primary-subheading {
    text-align: center;
  }

  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }

  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }

  .about-section-image-container {
    margin-right: 0rem !important;
  }

  .work-section-wrapper {
    margin-top: 5rem !important;
  }

  .work-section-bottom {
    margin-top: 1rem !important;
  }

  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }

  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }

  .footer-wrapper {
    flex-direction: column;
  }

  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }

  .footer-section-columns {
    margin: 1rem 0rem;
  }

  .App {
    width: 100vw;
    margin: 0;
  }

  .about-section-image-container {
    width: 95vw;
  }

  .demo-gif {
    height: 500px;
  }
}